import React from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";

const Index = () => {
  return (
    <div>
      <Navbar />

      <Sidebar />
      <main>
        <div className="hero-area fix">
          <div className="hero-slider slider__active swiper-container swiper-container-fade">
            <div className="swiper-wrapper p-relative">
              <div className="hero-pagination"></div>
              <div className="item-slider swiper-slide">
                <div
                  className="slide-bg"
                  style={{
                    backgroundImage: `url("/assets/img/bg/hero-1.jpg")`,
                  }}
                  //   dataBackground="/assets/img/bg/hero-1.jpg"
                ></div>
                <div className="container">
                  <div className="row ">
                    <div className="col-lg-12">
                      <div className="slider-all-text">
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                        >
                          WORLD LEAGUE
                        </h2>
                        <p
                          className="description mt-10 mb-10"
                          data-animation="fadeInUp"
                          data-delay=".6s"
                        >
                          UKL - ULTIMATE KARATE LEAGUE OF THE WORLD
                        </p>
                        <p
                          className="description mt-10 mb-10"
                          data-animation="fadeInUp"
                          data-delay=".6s"
                        > 12 December 2023 -to- 4 February 2024
                          <small>
                           
                            <br />
                            Live Telecast in 100+ countries
                          </small>
                        </p>
                      </div>
                      <div className="hero-social-icon">
                        <a
                          target="_blank"
                          href="https://www.facebook.com/UKL.WORLD"
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a target="_blank" href="https://twitter.com/uklworld">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/ukl.world"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                        {/* <a href="#">
                          <i className="fab fa-google"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-slider swiper-slide">
                <div
                  className="slide-bg"
                  style={{
                    backgroundImage: `url("/assets/img/bg/hero-2.jpg")`,
                  }}
                  //   data-background="/assets/img/bg/hero-2.jpg"
                ></div>
                <div className="container">
                  <div className="row ">
                    <div className="col-lg-12">
                      <div className="slider-all-text">
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                        >
                          WORLD LEAGUE
                        </h2>
                        <p
                          className="description mt-10 mb-50"
                          data-animation="fadeInUp"
                          data-delay=".6s"
                        >
                          UKL - ULTIMATE KARATE LEAGUE OF THE WORLD
                        </p>
                      </div>
                      <div className="hero-social-icon">
                        <a href="https://www.facebook.com/UKL.WORLD">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://twitter.com/uklworld">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/ukl.world">
                          <i className="fab fa-instagram"></i>
                        </a>
                        {/* <a href="#">
                          <i className="fab fa-google"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item-slider swiper-slide">
                <div
                  className="slide-bg"
                  style={{
                    backgroundImage: `url("assets/img/bg/hero-3.jpg")`,
                  }}
                  //   dataBackground="assets/img/bg/hero-3.jpg"
                ></div>
                <div className="container">
                  <div className="row ">
                    <div className="col-lg-12">
                      <div className="slider-all-text">
                        <h2
                          className="hero-title"
                          data-animation="fadeInUp"
                          data-delay=".4s"
                        >
                          WORLD LEAGUE
                        </h2>
                        <p
                          className="description mt-10 mb-50"
                          data-animation="fadeInUp"
                          data-delay=".6s"
                        >
                          UKL - ULTIMATE KARATE LEAGUE OF THE WORLD
                        </p>
                      </div>
                      <div className="hero-social-icon">
                        <a href="https://www.facebook.com/UKL.WORLD">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href="https://twitter.com/uklworld">
                          <i className="fab fa-twitter"></i>
                        </a>
                        <a href="https://www.instagram.com/ukl.world">
                          <i className="fab fa-instagram"></i>
                        </a>
                        {/* <a href="#">
                          <i className="fab fa-google"></i>
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tpfeatures-lists">
          <div className="container">
            <div className="row g-0">
              <div className="col-lg-2 col-md-2 col-md-4 col-6">
                <div
                  className="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/india.png" alt="" />
                  </div>
                  <h4>Own the Team </h4>
                  <span>INDIA</span>
                  <small>MAXIMUM SUBSCRIBERS : 100K</small>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-md-4 col-6">
                <div
                  className="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/uae.png" alt="" />
                  </div>
                  <h4>Own the Team</h4>
                  <span>UAE</span>
                  <small>MAXIMUM SUBSCRIBERS : 100K</small>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-md-4 col-6">
                <div
                  className="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/serbia.png" alt="" />
                  </div>
                  <h4>Own the Team</h4>
                  <span>SERBIA</span>
                  <small>MAXIMUM SUBSCRIBERS : 100K</small>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-md-4 col-6">
                <div
                  className="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/italy.png" alt="" />
                  </div>
                  <h4>Own the Team</h4>
                  <span>ITALY</span>
                  <small>MAXIMUM SUBSCRIBERS : 100K</small>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-md-4 col-6">
                <div
                  className="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/germany.png" alt="" />
                  </div>
                  <h4>Own the Team</h4>
                  <span>GERMANY</span>
                  <small>MAXIMUM SUBSCRIBERS : 100K</small>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-md-4 col-6">
                <div
                  className="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <div className="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/france.png" alt="" />
                  </div>
                  <h4>Own the Team</h4>
                  <span>FRANCE</span>
                  <small>MAXIMUM SUBSCRIBERS : 100K</small>
                </div>
              </div>
            </div>

            <p className="text-center">
              The Ultimate Karate League (UKL) is offering the opportunity to
              own a fraction or the full ownership of its country teams. This is
              a unique opportunity for entrepreneurs and investors to be part of
              a growing sports league.
            </p>
          </div>
        </div>

        <div className="tp-about-area pb-95 pt-80">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-6">
                <div
                  className="about-image mb-30 wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <img
                    src="/assets/img/about/about-1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                  <div className="about-shape">
                    <img src="/assets/img/about/about-shape.png" alt="" />
                    <div className="content">
                      <h4>Actions </h4>
                      <span>Unlimited</span>
                      {/* <span>Experience</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-6 ">
                <div className="about-info wow fadeInUp" data-wow-delay=".7s">
                  <div className="section-wrap">
                    <span className="tpsub-title mb-15">About us</span>
                    <h3 className="section-title mb-20">
                      Making UKL a huge Spectator Sport with 40-minutes match
                      format
                    </h3>
                    <span className="section-border mb-20">
                      <i className="far fa-circle"></i>
                    </span>
                    <p className="section-description mb-20">
                      Amateur Karate was not a spectator Sport and needed
                      complete OVERHAUL in its competition rules that would
                      attract spectators and enhance fan engagement.
                    </p>
                    <p className="section-description ">
                      “Power, Speed, Control and CombinaPons” are the FOUR
                      fundamental principles that guided the development of new
                      technical rules of competition. With approval of Dr Ilija
                      Jorga and Dr Vladimir Jorga, Sensei Rajeev Sinha
                      researched and developed new technical rules that
                      converted individual match of 2 minutes into 45-minutes
                      team match. Sensei termed this as KENKOGO defined as
                      ULTIMATE KARATE and is the owner of its IPR (Intellectual
                      Property Rights).
                    </p>

                    <p className="section-description ">
                      The new invented match format was successfully tested
                      before its launch on Discovery Channel’s Dsport
                      (Eurosport) for 18 weeks telecast in 2019. Its success was
                      noticed by all including KPMG that carried UKL success
                      story in and as Digital Future of India (August 2019
                      Report, page 210). The year 2020 had zero activity because
                      of prolonged COVID19 lock-down. 2021 witnessed super
                      successful event telecast LIVE on Sony TEN1 sports
                      network. In 2022, we focussed on development of World
                      League and set up its World HQ in Belgrade, Serbia. From
                      2023, UKL World League shall be on air across 100+
                      countries.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about-bg-shape">
            <img
              src="/assets/img/about/about-bg-png.png"
              alt=""
              className="img-fluid"
            />
          </div>
        </div>

        <div
          className="services-list mt-100"
          style={{
            backgroundImage: `url("/assets/img/services/services-bg.jpg")`,
          }}
          //   data-background="/assets/img/services/services-bg.jpg"
        >
          <div className="container custome-container">
            <div
              className="section-wrap-two text-center wow fadeInUp"
              data-wow-delay=".2s"
            >
              <span className="tpsub-title-two mb-15">
                <i className="far fa-circle"></i>Services
                <i className="far fa-circle"></i>
              </span>
              <h3 className="section-title-two  mb-30 white-color">
                Buy New Services
              </h3>
            </div>
            <div className="row mt-60">
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div
                  className="services-item text-center wow fadeInUp mb-30"
                  data-wow-delay=".6s"
                >
                  <div className="sv-inner sv-inner2">
                    <div className="services-icon">
                      <i className="fal fa-credit-card-front"></i>
                    </div>
                  </div>
                  <h4 className="services-item-title services-item-title-2 mb-20">
                    <a href="#">Privilege Card</a>
                  </h4>
                  <p className="mb-25">
                    Card that gives you Fractional Ownership Benefits of the
                    World Teams
                  </p>
                  <a href="#" className="services-item-btn">
                    Read More <i className="fal fa-chevron-double-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div
                  className="services-item text-center wow fadeInUp mb-30"
                  data-wow-delay=".9s"
                >
                  <div className="sv-inner sv-inner2">
                    <div className="services-icon">
                      <i className="fal fa-badge-check"></i>
                    </div>
                  </div>
                  <h4 className="services-item-title services-item-title-2 mb-20">
                    <a href="#">OTT SubscripAon</a>
                  </h4>
                  <p className="mb-25">
                    Exclusive OTT subscription that brings digital TV in your
                    pocket
                  </p>
                  <a href="#" className="services-item-btn">
                    Read More <i className="fal fa-chevron-double-right"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6">
                <div
                  className="services-item text-center wow fadeInUp mb-30"
                  data-wow-delay="1s"
                >
                  <div className="sv-inner sv-inner2">
                    <div className="services-icon">
                      <i className="fal fa-ticket-alt"></i>
                    </div>
                  </div>
                  <h4 className="services-item-title services-item-title-2 mb-20">
                    <a href="#">Tickets</a>
                  </h4>
                  <p className="mb-25">
                    Mega Events Tickets sale with wide range of hotel and
                    transportation offers
                  </p>
                  <a href="#" className="services-item-btn">
                    Read More <i className="fal fa-chevron-double-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tpcounter-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="counter-lists">
                  <div className="row g-0">
                    <div className="col-lg-3 col-md-6">
                      <div className="counter-item counter-item-border">
                        <div className="counter-number">
                          <h4>
                            <span className="counter">48</span>
                          </h4>
                        </div>
                        <div className="counterinfo">
                          <i className="fal fa-user-headset"></i>
                          <span>HOURS CONTENT</span>
                        </div>
                        <div className="counter-shape">
                          <img src="/assets/img/bg/counter-shape.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="counter-item counter-item-border">
                        <div className="counter-number">
                          <h4>
                            <span className="counter">5</span>
                          </h4>
                        </div>
                        <div className="counterinfo">
                          <i className="fal fa-comment-alt-smile"></i>
                          <span>Lectures</span>
                        </div>
                        <div className="counter-shape">
                          <img src="/assets/img/bg/counter-shape.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="counter-item counter-item-border">
                        <div className="counter-number">
                          <h4>
                            <span className="counter">480</span>
                          </h4>
                        </div>
                        <div className="counterinfo">
                          <i className="fal fa-farm"></i>
                          <span>Athletes Centres</span>
                        </div>
                        <div className="counter-shape">
                          <img src="/assets/img/bg/counter-shape.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="counter-item">
                        <div className="counter-number">
                          <h4>
                            <span className="counter">96</span>
                          </h4>
                        </div>
                        <div className="counterinfo">
                          <i className="fal fa-award"></i>
                          <span>OFFICIALS</span>
                        </div>
                        <div className="counter-shape">
                          <img src="/assets/img/bg/counter-shape.png" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="schedule-area pb-110">
          <div className="container custome-container mt-70">
            <div className="section-wrap-two text-center">
              <h3 className="section-title-two  mb-30">Country Team Council</h3>
            </div>
          </div>
          <div className="schedule-shape-bg fix">
            <img
              src="/assets/img/features/schedule-shape.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="schedule-shape-one fix">
            <img
              src="/assets/img/features/schedule-shape-1.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="schedule-shape-two fix">
            <img
              src="/assets/img/features/schedule-shape-2.png"
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="container custome-container ">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-4">
                <div className="country_team text-center">
                  <div className="tpfeatures-icon2">
                    <i className="far fa-users"></i>
                  </div>
                  <h3>Nominate Others</h3>
                  <p>
                    Nominate Privilege Card Owners to the Country Team Promotion
                    Board
                  </p>
                  <p>Opening from 15 December 2023 </p>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4">
                <div className="country_team text-center">
                  <div className="tpfeatures-icon2">
                    <i className="fal fa-user-check"></i>
                  </div>
                  <h3>File Your Nomination</h3>
                  <p>
                    File your nomination to the Country Team Promotion Board
                  </p>
                  <p>Opening from 15 December 2023</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="video-area p-relative"
          style={{
            backgroundImage: `url("/assets/img/bg/video-bg.jpg")`,
          }}
          //   data-background="/assets/img/bg/video-bg.jpg"
        >
          <div className="container">
            <div
              className="video-content text-center wow fadeInUp"
              data-wow-delay=".3s"
            >
              <a
                className="video-play-button ab-play_btn hero-play popup-video mb-45"
                href="https://youtu.be/xRjv9TNmnPk"
                target={"_blank"}
              >
                <i className="fas fa-play"></i>
              </a>
              <h4 className="video-title">
                World’s Best Athletes caught in 1 against 3 warriors rapid-fire
                situation
              </h4>
            </div>
          </div>
        </div>

        <div className="choose-us-area pt-120 pb-65">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-6">
                <div className="choose-iamge mb-30">
                  <img src="/assets/img/bg/choose.jpg" alt="choose-img" />
                </div>
              </div>
              <div className="col-xl-6 col-lg-6">
                <div className="choose-info">
                  <div className="section-wrap mb-35">
                    <h3 className="section-title mb-20">Players & Masters</h3>
                    <span className="section-border mb-30">
                      <i className="far fa-circle"></i>
                    </span>
                  </div>
                  <div className="choose-list mb-40">
                    <div className="choose-icon-wrap">
                      <div className="choose-icon">
                        <i className="fal fa-users-crown"></i>
                      </div>
                    </div>
                    <div className="choose-content">
                      <h5>Form The MAT</h5>
                      <p>
                        The performance of your athletes, judges, referees in
                        action on the mat
                      </p>
                    </div>
                  </div>
                  <div className="choose-list mb-40">
                    <div className="choose-icon-wrap">
                      <div className="choose-icon">
                        <i className="fal fa-bacon"></i>
                      </div>
                    </div>
                    <div className="choose-content">
                      <h5>Off The MAT</h5>
                      <p>
                        The performance, training and interviews of your
                        athletes, masters and technical officials
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Index;
