import React, { Fragment, useState, useEffect } from "react";
import ReactDatatable from "@mkikets/react-datatable";
import Footer1 from "../Common/Footer1";
import Navbar1 from "../Common/Navbar1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { Link } from "react-router-dom";
import moment from "moment";

const History = () => {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getLoginData();
  }, []);

  const getLoginData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getLoginHistory", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecord(respp.data);
        return false;
      }
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "created_at",
      text: "Date & Time",
      className: "created_at",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.created_at).format("lll")}</Fragment>;
      },
    },
    {
      key: "event",
      text: "Event",
      className: "event",
      align: "left",
      sortable: true,
    },
    {
      key: "device",
      text: "Device",
      className: "device",
      align: "left",
      sortable: true,
    },
    {
      key: "browser",
      text: "Browser",
      className: "browser",
      align: "left",
      sortable: true,
    },

    {
      key: "osName",
      text: "Operating System",
      className: "LDR",
      align: "left",
      sortable: true,
    },
    {
      key: "ip",
      text: "IP",
      className: "ip",
      align: "left",
      sortable: true,
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  return (
    <>
      <Navbar1 />
      <Sidebar1 />
      <main>
        <div class="dashbord_in">
          <div class="container custome-container">
            <h2 class="d_haddign">User History</h2>
            <div class="left_tab mb-3 mb-md-4">
              <ul>
                <li>
                  <Link to="/history" className="active">
                    Login History
                  </Link>
                </li>
                <li>
                  <Link to="/history-refer">Refer & Earn</Link>
                </li>
                <li>
                  <Link to="/history-privilegecard">Privilege Card</Link>
                </li>
                <li>
                  <Link to="/history-learn">Learn & Earn</Link>
                </li>
                <li>
                  <Link to="/history-support">Support & Earn</Link>
                </li>
                <li>
                  <Link to="/history-play">Play & Earn</Link>
                </li>
                <li>
                  <Link to="/history-tickets">Tickets</Link>
                </li>
                <li>
                  <Link to="/history-jobs">Jobs</Link>
                </li>
                <li>
                  <Link to="/history-ott"> OTT </Link>
                </li>
                <li>
                  <Link to="/history-promote"> Promote & Earn </Link>
                </li>
                <li>
                  <Link to="/history-watch"> Watch & Earn </Link>
                </li>
              </ul>
            </div>
            <div class="white_box">
              <div className="job-table-outer">
                <ReactDatatable
                  config={config}
                  records={record}
                  columns={columns}
                  onPageChange={pageChange}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </>
  );
};

export default History;
