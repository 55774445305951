import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";

const RajasthanTroopers = () => {
  return (
    <>
      {" "}
      <Navbar />
      <Sidebar />
      <main>
        <div
          class="page__title align-items-center"
          style={{ backgroundImage: 'url("assets/img/bg/login.jpg")' }}
        >
          <div class="container">
            <div class="page__title-content text-center">
              <h3 class="breadcrumb-title breadcrumb-title-sd mb-15">
                Rajasthan Troopers
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70 ">
          <div className="container custome-container">
            <div className="mblog_info mblog_details">
              <div className="medical_box mb-40 mt-20">
                <div className="row ">
                  <div className="col-md-5 col-lg-5">
                    <img
                      src="assets/img/rajasthan_troopers.png"
                      className="img-fluid border_img"
                      alt=""
                    />
                  </div>
                  <div className="col-lg-1 right_content"></div>
                  <div className="col-md-7 col-lg-6 right_content">
                    <div className="row ">
                      <div className="col-md-6 hhh">Team:</div>
                      <div className="col-md-6 ">Rajasthan Troopers</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Custodian:</div>
                      <div className="col-md-6 ">
                        Indian Professional Karate Council
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Valuation:</div>
                      <div className="col-md-6 ">INR 20,00,00,000</div>
                    </div>

                    <div className="row ">
                      <div className="col-md-6 hhh">Team Manager:</div>
                      <div className="col-md-6 ">Amit Khatri</div>
                    </div>
                    <div className="row ">
                      <div className="col-md-6 hhh">Team Members:</div>
                      <div className="col-md-6 ">
                        <ul>
                          <li>L. Rohiteshwar (Captain) India</li>
                          <li>Florin Pricob (Romania)</li>
                          <li>Viktoria Sokolova (Ukraine)</li>
                          <li> Umesh Saket (India)</li>
                          <li> Moninder Singh (India)</li>
                          <li>Praveen Kumar (India)</li>
                          <li>Dileep Kumar (India)</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default RajasthanTroopers;
