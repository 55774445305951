	import React, { useEffect } from "react";
import Footer1 from "../Common/Footer1";
import Navbar1 from "../Common/Navbar1";
import Sidebar1 from "../Common/Sidebar1";

const Dashboard = () => {
  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
  }, []);

  return (
    <>
      <Navbar1 />
      <Sidebar1 />
      <main>
        <div className="dashbord_in">
          <div className="container custome-container">
            <div className="row ">
              <div className="col-lg-3 col-md-6 col-sm-6">
                <a href="/privilegeCard">
                  <div
                    className="services-item text-center wow fadeInUp mb-30"
                    data-wow-delay=".6s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.6s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="sv-inner sv-inner2">
                      <div className="services-icon">
                        <i className="fal fa-credit-card-front"></i>
                      </div>
                    </div>
                    <h4 className="services-item-title services-item-title-2 mb-20">
                      <a href="/privilegeCard">Privilege Card</a>
                    </h4>
                    <p className="mb-25">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <a href="/ottSubscription">
                  <div
                    className="services-item text-center wow fadeInUp mb-30"
                    data-wow-delay=".9s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.9s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="sv-inner sv-inner2">
                      <div className="services-icon">
                        <i className="fal fa-badge-check"></i>
                      </div>
                    </div>
                    <h4 className="services-item-title services-item-title-2 mb-20">
                      <a href="/ottSubscription">OTT Subscription</a>
                    </h4>
                    <p className="mb-25">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <a href="/tickets">
                  <div
                    className="services-item text-center wow fadeInUp mb-30"
                    data-wow-delay="1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "1s",
                      animationName: "fadeInUp",
                    }}
                  >
                    <div className="sv-inner sv-inner2">
                      <div className="services-icon">
                        <i className="fal fa-ticket-alt"></i>
                      </div>
                    </div>
                    <h4 className="services-item-title services-item-title-2 mb-20">
                      <a href="/tickets">Tickets</a>
                    </h4>
                    <p className="mb-25">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </a>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <a href="/jobs">
                  <div
                    className="services-item text-center wow fadeInUp mb-30"
                    data-wow-delay="1s"
                    style={{
                      visibility: "visible",
                      animationDelay: "1s",
                      animationName: "fadeInUp",
                    }}
                    //   style="visibility: visible; animation-delay: 1s; animation-name: fadeInUp;"
                  >
                    <div className="sv-inner sv-inner2">
                      <div className="services-icon">
                        <i className="fal fa-briefcase"></i>
                      </div>
                    </div>
                    <h4 className="services-item-title services-item-title-2 mb-20">
                      <a href="/jobs">Jobs</a>
                    </h4>
                    <p className="mb-25">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry.
                    </p>
                  </div>
                </a>
              </div>
            </div>

            <div className="tpfeatures-lists tpfeatures_lists_dashbord">
              <div class="row g-0">
              <div class="col-lg-2 col-md-2 col-md-4 col-6">
                <div class="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s" >
                  <div class="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/india.png" alt="" />
                  </div>
				  <h4>Own	the	Team	</h4>
                  <span>INDIA</span>
				  <small>MAXIMUM	SUBSCRIBERS	:	100K</small>
                </div>
              </div>
			  <div class="col-lg-2 col-md-2 col-md-4 col-6">
                <div class="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s" >
                  <div class="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/uae.png" alt="" />
                  </div>
				  <h4>Own	the	Team</h4>
                  <span>UAE</span>
				  <small>MAXIMUM	SUBSCRIBERS	:	100K
</small>
                </div>
              </div>
			  <div class="col-lg-2 col-md-2 col-md-4 col-6">
                <div class="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s" >
                  <div class="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/serbia.png" alt="" />
                  </div>
				  <h4>Own	the	Team</h4>
                  <span>SERBIA</span>
				  <small>MAXIMUM	SUBSCRIBERS	:	100K</small>
                </div>
              </div>
			  <div class="col-lg-2 col-md-2 col-md-4 col-6">
                <div class="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s" >
                  <div class="tpfeatures-icon mb-25">
                    <img src="/assets/img/flag/italy.png" alt="" />
                  </div>
				  <h4>Own	the	Team</h4>
                  <span>ITALY</span>
				  <small>MAXIMUM	SUBSCRIBERS	:	100K</small>
                </div>
              </div>
			  <div class="col-lg-2 col-md-2 col-md-4 col-6">
                <div class="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s" >
                  <div class="tpfeatures-icon mb-25">
                     <img src="/assets/img/flag/uk.png" alt="" />
                  </div>
				  <h4>Own	the	Team</h4>
                  <span>UK</span>
				  <small>MAXIMUM	SUBSCRIBERS	:	100K</small>
                </div>
              </div>
			  <div class="col-lg-2 col-md-2 col-md-4 col-6">
                <div class="tp-features-item tp-features-item-border text-center mb-30 wow fadeInUp"
                  data-wow-delay=".2s" >
                  <div class="tpfeatures-icon mb-25">
                     <img src="/assets/img/flag/chnada.png" alt="" />
                  </div>
				  <h4>Own	the	Team</h4>
                  <span>CANADA</span>
				  <small>MAXIMUM	SUBSCRIBERS	:	100K</small>
                </div>
              </div>
            </div>
         </div>
          </div>
        </div>
      </main>
      <Footer1 />
    </>
  );
};

export default Dashboard;
