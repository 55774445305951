import React, { Fragment, useState, useEffect } from "react";
import Footer1 from "../../Common/Footer1";
import Navbar1 from "../../Common/Navbar1";
import Sidebar1 from "../../Common/Sidebar1";
import ReactDatatable from "@mkikets/react-datatable";
import axios from "axios";
import { Link } from "react-router-dom";
import moment from "moment";
import { baseUrl } from "../../Common/Baseurl";

const HistoryLearn = () => {
  const [record, setRecord] = useState([]);

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getLearnData();
  }, []);

  const getLearnData = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getHistory-learn", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setRecord(respp.data);
        return false;
      }
    });
  };

  const columns = [
    {
      key: "Sr No.",
      text: "Sr No.",
      className: "sr no.",
      align: "left",
      sortable: true,
      cell: (row, index) => index + 1,
    },
    {
      key: "name",
      text: "Name",
      className: "name",
      align: "left",
      sortable: true,
    },
    {
      key: "email",
      text: "Email",
      className: "email",
      align: "left",
      sortable: true,
    },
    {
      key: "phone",
      text: "Phone",
      className: "fees",
      align: "left",
      sortable: true,
    },
    {
      key: "country",
      text: "Country",
      className: "country",
      align: "left",
      sortable: true,
    },
    {
      key: "state",
      text: "State",
      className: "state",
      align: "left",
      sortable: true,
    },
    {
      key: "city",
      text: "City",
      className: "City",
      align: "left",
      sortable: true,
    },
    {
      key: "created_at",
      text: "Purchase Date",
      className: "created_at",
      align: "left",
      sortable: true,
      cell: (record) => {
        return <Fragment>{moment(record.created_at).format("ll")}</Fragment>;
      },
    },
  ];
  const config = {
    page_size: 10,
    length_menu: [10, 20, 50],
    filename: "Fund Request List",
    no_data_text: "No user found!",
    button: {
      print: true,
      csv: true,
    },
    language: {
      // length_menu: "Show MENU result per page",
      filter: "Filter in records...",
      // info: "Showing START to END of TOTAL records",
      pagination: {
        first: "First",
        previous: "Previous",
        next: "Next",
        last: "Last",
      },
    },
    show_length_menu: true,
    show_filter: true,
    show_pagination: true,
    show_info: true,
  };
  const pageChange = (pageData) => {
    console.log("OnPageChange", pageData);
  };

  return (
    <>
      <Navbar1 />
      <Sidebar1 />
      <div class="dashbord_in">
        <div class="container custome-container">
          <h2 class="d_haddign">Learn & Earn History</h2>
          <div class="left_tab mb-3 mb-md-4">
            <ul>
              <li>
                <Link to="/history">Login History</Link>
              </li>
              <li>
                <Link to="/history-refer">Refer & Earn</Link>
              </li>
              <li>
                <Link to="/history-privilegecard">Privilege Card</Link>
              </li>
              <li>
                <Link to="/history-learn" className="active">
                  Learn & Earn
                </Link>
              </li>
              <li>
                <Link to="/history-support">Support & Earn</Link>
              </li>
              <li>
                <Link to="/history-play">Play & Earn</Link>
              </li>
              <li>
                <Link to="/history-tickets">Tickets</Link>
              </li>
              <li>
                <Link to="/history-jobs">Jobs</Link>
              </li>
              <li>
                <Link to="/history-ott"> OTT </Link>
              </li>
              <li>
                <Link to="/history-promote"> Promote & Earn </Link>
              </li>
              <li>
                <Link to="/history-watch"> Watch & Earn </Link>
              </li>
            </ul>
          </div>
          <div class="white_box">
            <div className="job-table-outer">
              <ReactDatatable
                config={config}
                records={record}
                columns={columns}
                onPageChange={pageChange}
              />
            </div>
          </div>
        </div>
      </div>

      <Footer1 />
    </>
  );
};

export default HistoryLearn;
