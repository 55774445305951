import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { baseUrl } from "./Baseurl";
import {
  isMobile,
  isDesktop,
  isAndroid,
  isIOS,
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "react-device-detect";

const Navbar = () => {
  const [device, setDevice] = useState("");
  const [countryName, setCountryName] = useState("");
  const [cityName, setCityName] = useState("");

  var emaill = localStorage.getItem("email");

  useEffect(() => {
    DeviceName();
    getData();
    getGeoInfo();
  }, []);

  const getData = async () => {
    const response = await axios.get("https://ipapi.co/json/");
    let data = response.data;
    localStorage.setItem("ip", data.ip ? data.ip : "");
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryName(data.country_name);
        setCityName(data.city);
      })
      .catch((error) => {});
  };

  const DeviceName = () => {
    if (isMobile) {
      setDevice("Mobile");
      return false;
    }
    if (isAndroid) {
      setDevice("Android");
      return false;
    }
    if (isIOS) {
      setDevice("IOS");
      return false;
    }
    if (isDesktop) {
      setDevice("Desktop");
      return false;
    }
  };

  const logout = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const ipData = {
      ip: localStorage.getItem("ip"),
      device: device,
      browser: browserName + " " + browserVersion,
      osName: osName + " " + osVersion,
      cityName: cityName,
      countryName: countryName,
      msg: "Logged Out",
    };

    axios.post(baseUrl + "/auth/loginHistory", ipData, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        localStorage.clear();
        window.location.replace("/login");
      }
    });
  };

  const showHtml = () => {
    if (localStorage.getItem("token")) {
      return (
        <div className="user_btn profile_box">
          <a href="" data-bs-toggle="dropdown" className="dropdown-toggle">
            {emaill != undefined ? emaill : ""} <i className="fas fa-user"></i>
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link className="dropdown-item" to="/profile">
                <i className="fa fa-user-circle" aria-hidden="true"></i> Profile
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/wallet">
                <i className="fa fa-wallet"></i> Wallet
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to="/history">
                <i className="fa fa-history" aria-hidden="true"></i> History
              </Link>
            </li>
            <li>
              <button className="dropdown-item" onClick={logout}>
                <i className="fa fa-window-close" aria-hidden="true"></i> Logout{" "}
              </button>
            </li>
          </ul>
        </div>
      );
    } else if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/register"
    ) {
    } else {
      return (
        <div className="contact-btn">
          <Link to="/login" className="top-btn">
            Login/SignUp <i className="fas fa-user"></i>
          </Link>
        </div>
      );
    }
  };

  return (
    <>
      {/* <div className="progress-wrap">
        <svg
          className="progress-circle svg-content"
          width="100%"
          height="100%"
          viewBox="-1 -1 102 102"
        >
          <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
        </svg>
      </div> */}
      <header className="top-nav">
        <div className="top-address d-none d-lg-block">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-xxl-7 col-xl-5 col-lg-4 col-md-3">
                <div className="top-logo">
                  <Link to="/">
                    <img src="/assets/img/logo/logo.png" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-xxl-5 col-xl-7 col-lg-8 col-md-9">
                <div className="topbar-info">
                  <div className="contact-item contact-item2">
                    <i className="fal fa-envelope"></i>
                    <div className="content">
                      <p>Online Support</p>
                      <a href="mailto:info@ukl.world">info@ukl.world</a>
                    </div>
                  </div>
                  <div className="contact-item">
                    <i className="fal fa-phone-rotary"></i>
                    <div className="content">
                      <p>Free Contact</p>
                      <a href="tel:381654443950">(+381) 65 444 3950</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="menu-area" id="header-sticky">
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-xxl-12 col-xl-12 col-lg-12">
                <div className="topcontact-btn f-right d-none d-lg-block">
                  {showHtml()}
                </div>
                <div className="menu-content">
                  <div className="top-logo d-lg-none">
                    <Link to="/">
                      <img src="/assets/img/logo/logo.png" alt="" />
                    </Link>
                  </div>
                  <div className="main-menu sidebar__area"  id="demo">
                    <nav id="mobile-menu" > 
                      <ul>
                        <li>
                          <Link to="/aboutus" className="">
                            About Us{" "}
                          </Link>
                        </li>
                        <li className="dropdown">
                          <a href="#" data-bs-toggle="dropdown">
                            Country Leagues{" "}
                            <i className="fal fa-plus dropdown-icon"></i>
                          </a>
                          <ul className="sub-menu dropdown-menu">
                            <li>
                              <Link to="/country-league-india">UKL India</Link>
                            </li>
                            <li>
                              <Link to="#">UKL UAE</Link>
                            </li>
                            <li>
                              <Link to="#">UKL SERBIA</Link>
                            </li>
                            <li>
                              <Link to="#">UKL ITALY </Link>
                            </li>
                            <li>
                              <Link to="#">UKL GERMANY</Link>
                            </li>
                            <li>
                              <Link to="#">UKL FRANCE </Link>
                            </li>
                            {/* <li>
                              <Link to="#">UKL	CZECH	REPUBLIC</Link>
                            </li>
							<li>
                              <Link to="#">UKL	GERMANY</Link>
                            </li> */}
                          </ul>
                        </li>
						 <li className="dropdown">
                          <a href="#" data-bs-toggle="dropdown" >
                            WORLD LEAGUE{" "}
                            <i className="fal fa-plus dropdown-icon"></i>
                          </a>
                          <ul className="sub-menu dropdown-menu">
                            <li>
                              <Link to="/world-league">Countries</Link>
                            </li>
                            <li>
                              <Link to="/world-league">Schedule</Link>
                            </li>
                            <li>
                              <Link to="/athletes">Athletes</Link>
                            </li>
                          </ul>
                        </li>

                        <li className="dropdown">
                          <a href="#" data-bs-toggle="dropdown">TEAM OWNERSHIP <i className="fal fa-plus dropdown-icon"></i></a>
                          <ul className="sub-menu dropdown-menu">
                            <li>
                              <Link to="/team-ownership">Donors-Owners</Link>
                            </li>
                            <li>
                              <Link to="/team-ownership">Benefits</Link>
                            </li>
                            <li>
                              <Link to="/team-ownership">Process</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/athletes" className="">
                            Athletes{" "}
                          </Link>
                        </li>
                        <li>
                          <Link to="/world-ranking">WORLD RANKING </Link>
                        </li>
                        <li className="dropdown">
                          <a href="#" data-bs-toggle="dropdown"> 
                            TECHNICAL{" "}
                            <i className="fal fa-plus dropdown-icon"></i>
                          </a>
                          <ul className="sub-menu dropdown-menu">
                            <li>
                              <Link to="/matchRules">Match Rules</Link>
                            </li>
                            <li>
                              <Link to="/training">Training</Link>
                            </li>
                            <li>
                              <Link to="/matchRules">Referees</Link>
                            </li>
                          </ul>
                        </li>
                       <li className="dropdown">
                          <a href="#" data-bs-toggle="dropdown">
                            VIDEOS <i className="fal fa-plus dropdown-icon"></i>
                          </a>
                          <ul className="sub-menu dropdown-menu">
                            <li>
                              <Link to="/videos">Match Videos</Link>
                            </li>
                            <li>
                              <Link to="/videos">Training Videos</Link>
                            </li>
                            <li>
                              <Link to="/videos"> Non Tournament Videos</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link to="/polls">JOBS</Link>
                        </li>
                        {localStorage.getItem("token") ? (
                          <li>
                            <Link to="/dashboard">Dashboard</Link>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </nav>
                  </div>
                  <div className="side-menu-icon d-lg-none text-end">
                     <a href="#" data-bs-toggle="collapse" data-bs-target="#demo" ><i class="fal fa-bars"></i></a>
  
                    {/* <a href="javascript:void(0)">Link</a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;
