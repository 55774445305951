import React, { useState, useEffect } from "react";
import Navbar1 from "../Common/Navbar1";
import Footer1 from "../Common/Footer1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { CountryDropdown } from "react-country-region-selector";
import { baseUrl } from "../Common/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

const PlayEarn = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [check, setCheck] = useState(false);
  const [check1, setCheck1] = useState(0);
  // errors //
  const [errorName, setErrorName] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [errorCity, setErrorCity] = useState("");
  const [errorStates, setErrorStates] = useState("");
  const [errorZipcode, setErrorZipcode] = useState("");
  const [errorCheck, setErrorCheck] = useState("");

  let { id } = useParams();
  let decodedID = atob(id);

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
  }, []);

  const onChangeName = (e) => {
    let { name, value } = e.target;
    if (name === "Name") {
      if (!value) {
        setErrorName("Please enter name");
        setName(value);
        return false;
      }
      setErrorName("");
      setName(value);
    }
  };

  const onChangePhone = (e) => {
    let { name } = e.target;
    if (name === "Phone") {
      let value = e.target.value.trim();
      if (!value) {
        setErrorPhone("Please enter mobile number");
        setPhone(value);
        return false;
      }
      var regexNum = "^[0-9]*$";
      if (!value.match(regexNum)) {
        setErrorPhone("Please enter only numeric value");
        setPhone(value);
        return false;
      }
      if (value.length > 10) {
        setErrorPhone("Mobile number must be 10 digits");
        setPhone(value);
        return false;
      }
      if (value.length < 10) {
        setErrorPhone("Mobile number must be 10 digits");
        setPhone(value);
        return false;
      }
      setErrorPhone("");
      setPhone(value);
    }
  };

  const onChangeEmail = (e) => {
    let { name, value } = e.target;
    if (name === "Email") {
      if (!value) {
        setErrorEmail("Please enter email");
        setEmail(value);
        return false;
      }
      if (value.includes(" ")) {
        setErrorEmail("No blank space allowed");
        setEmail(value);
        return false;
      }
      var emailReg =
        /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;
      if (!value.match(emailReg)) {
        setErrorEmail("Please enter a valid email");
        setEmail(value);
        return false;
      } else {
        setErrorEmail("");
      }
      setEmail(value);
      setErrorEmail("");
    }
  };

  const onChangeCountry = (e) => {
    if (!e) {
      setErrorCountry("Please select country");
      setCountry(e);
      return false;
    } else {
      setErrorCountry("");
      setCountry(e);
    }
  };

  const onChangeStates = (e) => {
    let { name, value } = e.target;
    if (name === "State") {
      if (!value) {
        setErrorStates("Please enter state");
        setStates(value);
        return false;
      }
      setErrorStates("");
      setStates(value);
    }
  };

  const onChangeCity = (e) => {
    let { name, value } = e.target;
    if (name === "City") {
      if (!value) {
        setErrorCity("Please enter city");
        setCity(value);
        return false;
      }
      setErrorCity("");
      setCity(value);
    }
  };

  const onChangeZipcode = (e) => {
    let { name, value } = e.target;
    if (name === "Zipcode") {
      if (!value) {
        setErrorZipcode("Please enter zipcode");
        setZipcode(value);
        return false;
      }
      var regexZipcode = "^[0-9]*$";
      if (!value.match(regexZipcode)) {
        setErrorZipcode("Please enter only numeric value");
        setZipcode(value);
        return false;
      }
      if (value.length < 5) {
        setErrorZipcode("Please enter minimum 5 digits");
        setZipcode(value);
        return false;
      }
      if (value.length > 5) {
        setErrorZipcode("Please enter minimum 5 digits");
        setZipcode(value);
        return false;
      }
      var regexZipcode1 = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
      if (!value.match(regexZipcode1)) {
        setErrorZipcode("Please enter valid input");
        setZipcode(value);
        return false;
      }
      if (value <= 0) {
        setErrorZipcode("Please enter valid input");
        setZipcode(value);
        return false;
      } else {
        setErrorZipcode("");
        setZipcode(value);
      }

      setErrorZipcode("");
      setZipcode(value);
    }
  };

  const onChangeCheck = (e) => {
    var check1 = e.target.checked;
    if (check1 === true) {
      setErrorCheck("");
      setCheck(check1);
      setCheck1(1);
      return false;
    }
    if (check1 === false) {
      setErrorCheck("This field is required");
      setCheck(check1);
      setCheck1(0);
      return false;
    }
    setErrorCheck("");
    setCheck(check1);
    setCheck1(0);
  };

  const submitForm = (e) => {
    e.preventDefault();
    var regexZipcode = "^[0-9]*$";
    var regexZipcode1 = /(?!\s*$)[-a-zA-Z0-9_:,.\s]{1,100}$/;
    var emailReg =
      /^([a-zA-Z\d\.-]+)@([a-zA-Z\.-]+)\.([a-zA-Z]{2,8})([a-zA-Z]{2,8})?$/;

    if (
      !name &&
      !phone &&
      !email &&
      !country &&
      !city &&
      !states &&
      check === false &&
      !zipcode
    ) {
      setErrorName("Please enter name");
      setErrorPhone("Please enter mobile number");
      setErrorEmail("Please enter email");
      setErrorCountry("Please select country");
      setErrorStates("Please enter state");
      setErrorCity("Please enter city");
      setErrorZipcode("Please enter zipcode");
      setErrorCheck("This field is required");
      return false;
    }

    if (!name) {
      setErrorName("Please enter name");
      return false;
    }

    if (!phone) {
      setErrorPhone("Please enter mobile number");
      return false;
    }
    var regexNum = "^[0-9]*$";
    if (!phone.match(regexNum)) {
      setErrorPhone("Please enter only numeric value");
      return false;
    }
    if (phone.length > 10) {
      setErrorPhone("Mobile number must be 10 digits");
      return false;
    }
    if (phone.length < 10) {
      setErrorPhone("Mobile number must be 10 digits");
      return false;
    }

    if (!email) {
      setErrorEmail("Please enter email");
      return false;
    }
    if (email.includes(" ")) {
      setErrorEmail("No blank space allowed");
      return false;
    }
    if (!email.match(emailReg)) {
      setErrorEmail("Please enter a valid email");
      return false;
    }

    if (country === "" || !country || country === undefined) {
      setErrorCountry("Please select country");
      return false;
    }

    if (!states) {
      setErrorStates("Please enter state");
      return false;
    }

    if (!city) {
      setErrorCity("Please enter city");
      return false;
    }

    if (!zipcode) {
      setErrorZipcode("Please enter zipcode");
      return false;
    }
    if (!zipcode.match(regexZipcode)) {
      setErrorZipcode("Please enter only numeric value");
      return false;
    }
    if (!zipcode.match(regexZipcode1)) {
      setErrorZipcode("Please enter valid input");
      return false;
    }
    if (zipcode.length < 5) {
      setErrorZipcode("Please enter minimum 5 digits");
      return false;
    }
    if (zipcode.length > 5) {
      setErrorZipcode("Please enter minimum 5 digits");
      return false;
    }
    if (check === false) {
      setErrorCheck("This field is required");
      setCheck(check);
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = {
      name: name,
      phone: phone,
      email: email,
      country: country,
      states: states,
      city: city,
      zipcode: zipcode,
      check: check1,
      contestId: decodedID,
    };
    axios.post(baseUrl + "/auth/playEarn", data, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        toast.success(respp.message);
        setTimeout(() => {
          window.location.href = "/dashboard";
        }, 4000);
        return false;
      }
      toast.dismiss();
      toast.error(respp.message);
    });
  };
  return (
    <>
      <Navbar1 />
      <Sidebar1 />
      <main>
        <div className="dashbord_in">
          <div className="container custome-container">
            <h2 className="d_haddign">Play & Earn</h2>

            <div className="white_box">
              <div className="row">
                <div className="form-group col-md-6">
                  <input
                    placeholder="Name"
                    name="Name"
                    value={name}
                    className="form-control"
                    onChange={onChangeName}
                  />
                  <div style={{ color: "red" }}>{errorName}</div>
                </div>
                <div className="form-group col-md-6">
                  <input
                    placeholder="Phone no"
                    name="Phone"
                    className="form-control"
                    value={phone}
                    onChange={onChangePhone}
                  />
                  <div style={{ color: "red" }}>{errorPhone}</div>
                </div>
                <div className="form-group col-md-6">
                  <input
                    placeholder="Email"
                    name="Email"
                    value={email}
                    className="form-control"
                    onChange={onChangeEmail}
                  />
                  <div style={{ color: "red" }}>{errorEmail}</div>
                </div>
                <div className="form-group col-md-6">
                  <CountryDropdown
                    className="form-control"
                    name="Country"
                    value={country}
                    onChange={(e) => onChangeCountry(e)}

                    //   disabled={btnRes}
                  />
                  <div style={{ color: "red" }}>{errorCountry}</div>
                </div>
                <div className="form-group col-md-6">
                  <input
                    placeholder="State"
                    name="State"
                    className="form-control"
                    value={states}
                    onChange={onChangeStates}
                  />
                  <div style={{ color: "red" }}>{errorStates}</div>
                </div>
                <div className="form-group col-md-6">
                  <input
                    placeholder="City"
                    name="City"
                    className="form-control"
                    value={city}
                    onChange={onChangeCity}
                  />
                  <div style={{ color: "red" }}>{errorCity}</div>
                </div>
                <div className="form-group col-md-6">
                  <input
                    placeholder=" Pincode/Zipcode"
                    name="Zipcode"
                    className="form-control"
                    value={zipcode}
                    onChange={onChangeZipcode}
                  />
                  <div style={{ color: "red" }}>{errorZipcode}</div>
                </div>
                <div className="mb-3 form-check ml-20">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    name="check"
                    value={check}
                    onChange={onChangeCheck}
                  />

                  <label className="form-check-label" for="exampleCheck1">
                    Terms & Condition
                  </label>
                  <div style={{ color: "red" }}>{errorCheck}</div>
                </div>
                <div class="col-md-5">
                  <button class="btn btn_man" onClick={submitForm}>
                    {" "}
                    Pay Now
                  </button>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </main>
      <Footer1 />
    </>
  );
};

export default PlayEarn;
