import React, { useState, useEffect } from "react";
import Footer1 from "../Common/Footer1";
import Navbar1 from "../Common/Navbar1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import moment from "moment";
const Tickets = () => {
  const [ticketsData, setTicketsData] = useState([]);
  const [price, setPrice] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [totalTickets, setTotalTickets] = useState(0);
  const [availableTickets, setAvailableTickets] = useState(0);

  const defaultImage = "assets/img/pay.jpg";

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getData();
  }, []);

  const getData = () => {
    const config = {
      headers: {
        Authorisation: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getTickets", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setTicketsData(respp.data);
        //   setPlayStatus(respp.success);
        return false;
      }
      //   setPlayStatus(respp.success);
    });
  };
  const TotalTickets = (e) => {
    let name = e.target.id;
    if (name === "add") {
      setTotalTickets(totalTickets + 1);
      var newTickets = totalTickets + 1;

      if (newTickets > availableTickets) {
        setTotalTickets(availableTickets);
        return false;
      }
    }
    if (name === "remove") {
      if (totalTickets === 0) {
        return false;
      }
      setTotalTickets(totalTickets - 1);
    }
  };
  const InitialValues = (value, id, time, date, availableTickets) => {
    setPrice(value);
    setTicketId(id);
    setTotalTickets(0);
    setAvailableTickets(availableTickets);
  };
  const buyTickets = (e) => {
    e.preventDefault();

    var totalPrice = price * totalTickets;

    if (totalTickets === 0 && totalPrice === 0) {
      toast.dismiss();
      toast.error("Please add minimum 1 ticket");
      return false;
    }

    if (totalTickets === 0) {
      toast.dismiss();
      toast.error("Please add minimum 1 ticket");
      return false;
    }

    if (totalPrice === 0) {
      toast.dismiss();
      toast.error("Please add tickets again");
      return false;
    }

    if (availableTickets === 0) {
      toast.dismiss();
      toast.error("All tickets are sold out");
      return false;
    }
    if (totalTickets > availableTickets) {
      toast.dismiss();
      toast.error("Please add tickets not more than available tickets");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };

    const data = {
      ticket_id: ticketId,
      ticket: totalTickets,
      price: totalPrice,
      availableTickets: availableTickets,
      id: localStorage.getItem("id"),
    };

    axios.post(baseUrl + "/auth/buyTicket", data, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        closeModal();
        toast.dismiss();
        getData();
        toast.success(respp.message);

        return false;
      } else {
        toast.dismiss();
        toast.error(respp.message);
        return false;
      }
    });
  };

  const closeModal = () => {
    $("#exampleModal").modal("hide");
    setTotalTickets(0);
  };

  const buyPopUp = () => {
    return (
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div
            class="modal-content"
            style={{
              width: "106%",
            }}
          >
            <div class="modal-header">
              <h4>How Much Tickets You want To Buy</h4>
            </div>
            <div class="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <h3>Tickets-: {totalTickets}</h3>
                </div>
                <div className="col-md-6">
                  <h3>Available Tickets-: {availableTickets} </h3>
                </div>
              </div>

              <h3>Total Price-: {totalTickets * price}</h3>
            </div>
            <div class="modal-body text-center">
              <button
                className="btn btn-primary"
                id="add"
                onClick={TotalTickets}
              >
                Add +
              </button>
              {"  "}
              <button
                className="btn btn-primary"
                id="remove"
                onClick={TotalTickets}
              >
                Remove -
              </button>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={buyTickets}
              >
                Buy
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showData = () => {
    var html = [];
    var date = new Date();
    var newTime = moment(date).format("HH:mm");

    ticketsData.map(function (value, i) {
      if (value.time > newTime) {
        html.push(
          <>
            <div class="col-lg-6 mb-3 mb-md-4">
              <div class="white_box play_earn h100">
                <div class="row">
                  <div class="form-group col-md-5">
                    <img
                      src={
                        value.ticket_image === null
                          ? defaultImage
                          : baseUrl + "/static/upload/" + value.ticket_image
                      }
                      alt="ticket_image"
                      class="img-fluid play_pay_img"
                    />
                  </div>
                  <div class="form-group col-md-7 p-md-0">
                    <ul class="list_dital">
                      <li>
                        <span class="lable_">Event Name</span>
                        {value.name}
                      </li>
                      <li>
                        <span class="lable_">Event Date</span>
                        {value.date.split("T")[0]}
                      </li>
                      <li>
                        <span class="lable_">Event Time</span>
                        {value.time}
                      </li>
                      <li>
                        <span class="lable_">Venue</span>
                        {value.venue}
                      </li>
                      <li>
                        <span class="lable_">Price</span>
                        {value.price}
                      </li>
                    </ul>
                  </div>
                  <div class="row align-items-end">
                    <div class=" col-md-9">
                      <b class="mt-0">Description</b>
                      <ul class="dit_ul">
                        <li>{value.description}</li>
                      </ul>
                    </div>
                    <div class="col-md-3 text-right">
                      <button
                        type="button"
                        class="btn btn-primary"
                        data-bs-toggle="modal"
                        onClick={() =>
                          InitialValues(
                            value.price,
                            value.id,
                            value.time,
                            value.date,
                            value.total_ticket
                          )
                        }
                        data-bs-target="#exampleModal"
                      >
                        Buy
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      }
    });
    return html;
  };

  return (
    <>
      <Navbar1 />
      <Sidebar1 />

      <main>
        <div class="dashbord_in">
          <div class="container custome-container">
            <h2 class="d_haddign">Tickets</h2>
            <div class="row">
              {showData()}
              {buyPopUp()}
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </main>
      <Footer1 />
    </>
  );
};

export default Tickets;
