import React from "react";
import Navbar from "../../Common/Navbar";
import Sidebar from "../../Common/Sidebar";
import Footer from "../../Common/Footer";

const PreSeason = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          className="page__title align-items-center"
          style={{
            backgroundImage: 'url("assets/img/bg/login.jpg")',
          }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
                Pre Season
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
            <div className="row">
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-md-3 mb-4">
                <div className="marketng_box text-center">
                  <img
                    src="assets/img/seasons1.jpg"
                    alt="Event	Sponsorship"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default PreSeason;
