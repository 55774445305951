import React from "react";
import Footer from "../Common/Footer";
import Navbar from "../Common/Navbar";
import Sidebar from "../Common/Sidebar";

const TeamOwnership = () => {
  return (
    <>
      <Navbar />
      <Sidebar />
      <main>
        <div
          className="page__title align-items-center"
          style={{
            backgroundImage:'url("/assets/img/bg/team_ownership-bg.jpg")',
          }}
        >
          <div className="container">
            <div className="page__title-content text-center">
              <h3 className="breadcrumb-title breadcrumb-title-sd mb-15">
                Team Ownership
              </h3>
            </div>
          </div>
        </div>

        <div className="blog_area pt-80 pb-70">
          <div className="container custome-container">
            <h3 className="mblog__title mb-15">Fractional ownership of UKL Country Teams</h3>
            <p>Ultimate Karate League (UKL) is a professional martial arts league that has gained immense popularity over the years. The league is known for its high-octane fights, incredible athletes, and the fierce competition that takes place on the mat. In recent times, UKL has introduced an innovative way for fans to get involved with their favorite teams - fractional ownership of country teams.</p>
            <p>Fractional ownership is a concept that has been around for quite some time, but it has recently gained popularity in the sports industry. The idea is simple - fans can buy a small percentage of ownership in a team, which entitles them to a share of the team's profits, as well as a say in how the team is run.</p>
            <p>With UKL's fractional ownership program, fans can now become part-owners of their favorite country team. The program is designed to give fans a more active role in the league and to help teams generate more revenue.</p>
            <p>To participate in the program, fans can purchase a fractional ownership stake in one of the country teams. The ownership stake is divided into small increments, making it aﬀordable for fans to get involved. Once a fan becomes an owner, they are entitled to certain perks such as team merchandise, exclusive access to team events, and the ability to vote on team decisions.</p>
            <p>However, fractional ownership is not just about perks and benefits. Owners also have a say in how the team is run. They can vote on important team decisions such as the hiring of coaches, player trades, and sponsorship deals. This gives fans a real sense of ownership and a more active role in the success of their favorite team.</p>
            <p>Moreover, fractional ownership also benefits the teams themselves. With the additional revenue generated from fractional ownership, teams can invest in new training facilities, better equipment, and even bring in new talent. This helps to ensure that the league remains competitive and that the teams continue to produce top-quality athletes.</p>
            <p>In conclusion, fractional ownership of UKL country teams is an innovative way for fans to get more involved with the league and their favorite teams. It gives fans a sense of ownership and control while also providing teams with additional revenue to invest in their future success. With the popularity of UKL continuing to grow, fractional ownership is sure to become an even more integral part of the league's success.</p>
			
			
			
            <h3 className="mblog__title mb-15">Donors-Owners</h3>
            <div className="row">
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="marketng_box text-center h100">
                  <img
                    src="/assets/img/fractional_ownership.jpg"
                    alt="india"
                    className="img-fluid"
                  />
                  <h5>
                    Fractional Ownership 
                  </h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="marketng_box text-center h100">
                  <img
                    src="/assets/img/free_gift.jpg"
                    alt="UAE"
                    className="img-fluid"
                  />
                  <h5>Fractional Ownership</h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 mb-3">
                <div className="marketng_box text-center h100">
                  <img
                    src="/assets/img/transferability.jpg"
                    alt="Serbia"
                    className="img-fluid"
                  />
                  <h5>Transferability of Donor Cards</h5>
                </div>
              </div>
            </div>

            <h3 className="mblog__title mb-15 mt-40">Benefits</h3>
            <div className="row">
              <div className="col-lg-6 col-md-4 mb-3">
                <div className="marketng_box text-center h100">
                  <img
                    src="/assets/img/social_benefits.jpg"
                    alt="Social	Benefits"
                    className="img-fluid"
                  />
                  <h4>Social Benefits</h4>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 mb-3">
                <div className="marketng_box text-center h100">
                  <img
                    src="/assets/img/financial_benefits.jpg"
                    alt="social_benefits"
                    className="img-fluid"
                  />
                  <h4>Financial Benefits</h4>
                </div>
              </div>
            </div>

            <h3 className="mblog__title mb-15 mt-40">Process</h3>
            <div className="row">
              <div className="col-md-4 mb-3">
                <div className="marketng_box text-center h100">
                  <img
                    src="/assets/img/application.png"
                    alt="Application"
                    className="img-fluid"
                  />
                  <h4>Application</h4>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="marketng_box text-center h100">
                  <img
                    src="/assets/img/payment.png"
                    alt="social_benefits"
                    className="img-fluid"
                  />
                  <h4>Payment</h4>
                </div>
              </div>
              <div className="col-md-4 mb-3">
                <div className="marketng_box text-center h100">
                  <img
                    src="/assets/img/card_i.png"
                    alt="card_i"
                    className="img-fluid"
                  />
                  <h4>
                    Process to claim Gia worth the Value of the Donor Card at
                    Sponsors’ site
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default TeamOwnership;
