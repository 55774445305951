import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Authentication/Login";
import Register from "./components/Authentication/Register";
import Forgot from "./components/Authentication/Forgot";
import Index from "./components/Index/Index";
import Dashboard from "./components/Authentication/Dashboard";
import Kyc from "./components/Files/Kyc";
import Whitelist from "./components/Files/Whitelist";
import PrivilegeCard from "./components/Files/PriviligeCard";
import PromoteNEarn from "./components/Refer/PromoteNEarn";
import Refer from "./components/Refer/Refer";
import SupportEarn from "./components/Refer/SupportEarn";
import ApplyJobs from "./components/Files/ApplyJobs";
import Learn from "./components/Refer/PayLearn";
import ELearning from "./components/Refer/ELearning";
import PlayEarn from "./components/Refer/PlayEarn";
import Play from "./components/Refer/Play";
import Profile from "./components/Files/Profile";
import Tickets from "./components/Files/Tickets";
import Jobs from "./components/Files/Jobs";
import Wallet from "./components/Files/Wallet";
import History from "./components/Files/History";
import HistoryWatch from "./components/Files/History/HistoryWatch";
import HistoryLearn from "./components/Files/History/HistoryLearn";
import HistorySupport from "./components/Files/History/HistorySupport";
import HistoryOTT from "./components/Files/History/HistoryOTT";
import HistoryPrivilegecard from "./components/Files/History/HistoryPrivilegecard";
import HistoryTickets from "./components/Files/History/HistoryTickets";
import HistoryPromote from "./components/Files/History/HistoryPromote";
import HistoryPlay from "./components/Files/History/HistoryPlay";
import HistoryRefer from "./components/Files/History/HistoryRefer";
import Organisa from "./components/Files/Organisa";
import Medical from "./components/Files/Medical";
import Commercial from "./components/Files/Commercial";
import TeamOwnership from "./components/Files/TeamOwnership";
import WorldRanking from "./components/Files/WorldRanking";
import WorldLeague from "./components/Files/WorldLeague";
import Videos from "./components/Files/Videos";
import Athletes from "./components/Files/Extras/Athletes";
import MatchRules from "./components/Files/Extras/MatchRules";
import Polls from "./components/Files/Extras/Polls";
import UKLIndia from "./components/Files/Extras/UKLIndia";
import AboutUs from "./components/Files/Extras/AboutUs";
import Training from "./components/Files/Extras/Training";
import UPRebels from "./components/Files/Extras/UPRebels";
import RajasthanTroopers from "./components/Files/Extras/RajasthanTroopers";
import PuneDivine from "./components/Files/Extras/PuneDivine";
import MumbaiNinjas from "./components/Files/Extras/MumbaiNinjas";
import PunjabFighters from "./components/Files/Extras/PunjabFighters";
import DelhiBravehearts from "./components/Files/Extras/DelhiBravehearts";
import PreSeason from "./components/Files/Extras/PreSeason";
import HistoryJobs from "./components/Files/History/HistoryJobs";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/wallet" element={<Wallet />} />
          <Route path="/organisa" element={<Organisa />} />
          <Route path="/medical" element={<Medical />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route path="/team-ownership" element={<TeamOwnership />} />
          <Route path="/world-ranking" element={<WorldRanking />} />
          <Route path="/world-league" element={<WorldLeague />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/athletes" element={<Athletes />} />
          <Route path="/matchRules" element={<MatchRules />} />
          <Route path="/polls" element={<Polls />} />
          <Route path="/country-league-india" element={<UKLIndia />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/training" element={<Training />} />

          <Route path="/uprebels" element={<UPRebels />} />
          <Route path="/rajasthantroopers" element={<RajasthanTroopers />} />
          <Route path="/punedivine" element={<PuneDivine />} />
          <Route path="/mumbaininjas" element={<MumbaiNinjas />} />
          <Route path="/punjabfighters" element={<PunjabFighters />} />
          <Route path="/delhibravehearts" element={<DelhiBravehearts />} />

          <Route path="/pre-season" element={<PreSeason />} />

          <Route path="/kyc" element={<Kyc />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/whitelist" element={<Whitelist />} />
          <Route path="/privilegeCard" element={<PrivilegeCard />} />
          <Route path="/promoteEarn" element={<PromoteNEarn />} />
          <Route path="/referEarn" element={<Refer />} />
          <Route path="/supportEarn" element={<SupportEarn />} />

          <Route path="/eLearn" element={<ELearning />} />
          <Route path="/learnEarn/:id" element={<Learn />} />

          <Route path="/applyJobs/:id" element={<ApplyJobs />} />
          <Route path="/play" element={<Play />} />
          <Route path="/play/playEarn/:id" element={<PlayEarn />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/history" element={<History />} />

          <Route path="/history-learn" element={<HistoryLearn />} />
          <Route path="/history-support" element={<HistorySupport />} />
          <Route path="/history-ott" element={<HistoryOTT />} />
          <Route path="/history-refer" element={<HistoryRefer />} />
          <Route
            path="/history-privilegecard"
            element={<HistoryPrivilegecard />}
          />
          <Route path="/history-tickets" element={<HistoryTickets />} />
          <Route path="/history-jobs" element={<HistoryJobs />} />
          <Route path="/history-promote" element={<HistoryPromote />} />
          <Route path="/history-play" element={<HistoryPlay />} />

          <Route path="/history-watch" element={<HistoryWatch />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
