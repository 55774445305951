import React, { useState, useEffect } from "react";
import Navbar1 from "../Common/Navbar1";
import Footer1 from "../Common/Footer1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
const Wallet = () => {
  const [data, setData] = useState([]);
  const [total, setTotal] = useState("");

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getReferral();
    getWalletTotal();
  }, []);

  const getReferral = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = {
      id: localStorage.getItem("id"),
    };
    axios.post(baseUrl + "/auth/walletReferral", data, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setData(respp.data);
      }
    });
  };

  const getWalletTotal = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    const data = {
      id: localStorage.getItem("id"),
    };
    axios.post(baseUrl + "/auth/walletSum", data, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setTotal(respp.data[0].total);
      }
    });
  };

  const dataHtml = () => {
    let html = [];
    data.map(function (value, i) {
      // data.map((value) => (
      html.push(
        <tr>
          <td> {i + 1} </td>
          <td> {value.referralName} </td>
          <td> {value.amount} </td>
          <td>{value.transaction_type} </td>
          <td>{moment(value.created).format("lll")}</td>
          <td>
            {value.pan_image_status === "1" ? "Pending" : ""}
            {value.pan_image_status === "2" ? "Verified" : ""}
            {value.pan_image_status === "0" ? "Rejected" : ""}
          </td>
          <td>
            {value.selfie_status === "1" ? "Pending" : ""}
            {value.selfie_status === "2" ? "Verified" : ""}
            {value.selfie_status === "0" ? "Rejected" : ""}
          </td>
          {/* <td className="action">
              <Link to={"/show-pending-kyc/" + value.id + "/" + show }>
                <img
                  src="assets/images/edit-icon.png"
                  alt=""
                  className="img-fluid"
                />
              </Link>
            </td> */}
        </tr>
      );
    });
    return html;
  };

  return (
    <>
      <Navbar1 />
      <Sidebar1 />

      <main>
        <div class="dashbord_in">
          <div class="container custome-container">
            <h1 class="d_haddign">Wallet</h1>
            <div class="row">
              <div class="col-md-3">
                <div class="white_box">
                  <h4 class="d_haddign">Your Income</h4>
                  <h2 className="d_haddign"> {total} $</h2>

                  {console.log("12345", total)}
                  <div class="text-right mt-3">
                    <button className="btn btn-primary "> Withdraw </button>
                  </div>
                </div>
              </div>

              <div class="col-md-9">
                <div class="white_box">
                  <table className="w-100 table table-striped">
                    <tbody>
                      <tr>
                        <th>Sr No.</th>
                        <th>Referral Name</th>
                        <th>Amount</th>
                        <th>Type</th>
                        <th>Date</th>
                        <th></th>
                        <th></th>
                      </tr>
                      {dataHtml()}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </main>
      <Footer1 />
    </>
  );
};

export default Wallet;
