import React from 'react'

const Sidebar1 = () => {
  return (
   <>
   <div className="sidebar__area">
         <div className="sidebar__wrapper sidebar-opened">
            <div className="sidebar__close">
               <button className="sidebar__close-btn" id="sidebar__close-btn">
                  <i className="fal fa-times"></i>
               </button>
            </div>
            <div className="sidebar__content">
               <div className="sidebar__logo mb-40">
                  <a href="index.html">
                  <img src="/assets/img/logo/logo.png" alt="logo"/>
                  </a>
               </div>
              
               <div className="mobile-menu fix"></div>
              
            </div>
         </div>
      </div>
      <div className="body-overlay"></div>
      </>
  )
}

export default Sidebar1