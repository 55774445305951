import React, { useState, useEffect } from "react";
import Navbar1 from "../Common/Navbar1";
import Footer1 from "../Common/Footer1";
import Sidebar1 from "../Common/Sidebar1";
import axios from "axios";
import { baseUrl } from "../Common/Baseurl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PriviligeCard = () => {
  const [country, setCountry] = useState("");
  const [amount, setAmount] = useState("");
  const [errorCountry, setErrorCountry] = useState("");
  const [errorAmount, setErrorAmount] = useState("");
  const [getCountry, setGetCountry] = useState("");
  const [getAmount, setGetAmount] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [data, setData] = useState([]);
  const [dataStatus, setDataStatus] = useState(false);
  const [privilegeStatus, setPrivilegeStatus] = useState();
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (!localStorage.token) {
      window.location.href = "/login";
    }
    getPriviligeDataAdmin();
    getPriviligeData();
  }, []);

  const getPriviligeDataAdmin = (e) => {
    axios.post(baseUrl + "/auth/getPriviligeCardAdmin", {}).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setData(respp.data);
        setDataStatus(true);
      }
    });
  };

  const getPriviligeData = (e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    axios.post(baseUrl + "/auth/getPriviligeCard", {}, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        setStatus(respp.success);
        setGetCountry(respp.data[0].country);
        setGetAmount(respp.data[0].amount);
        setFirstName(respp.data[0].fname);
        setLastName(respp.data[0].lname);
        setPrivilegeStatus(respp.data[0].status);
      }
    });
  };

  const paymentMethodDropdown = () => {
    if (dataStatus === true) {
      const html = [];
      html.push(<option value="">Select</option>);
      var sortedData = data.sort();

      data.sort(function (a, b) {
        if (a.country < b.country) {
          return -1;
        }
        if (a.country > b.country) {
          return 1;
        }
        return 0;
      });
      sortedData.map(function (value, i) {
        return html.push(<option value={value.id}>{value.country}</option>);
      });

      return (
        <select
          class="form-control"
          name="country"
          onChange={handleChangeCountry}
        >
          {html}
        </select>
      );
    }
  };

  const handleChangeCountry = (e) => {
    let { name, value } = e.target;

    if (name === "country") {
      if (value) {
        setErrorCountry("");
        const data = {
          country_id: value,
        };

        axios.post(baseUrl + "/auth/getCountryById", data).then((resp) => {
          var respp = resp.data;
          if (respp.success === true) {
            setAmount(respp.data[0].amount);
            setCountry(respp.data[0].country);
            return false;
          }
        });
        return false;
      }
      if (!value) {
        setErrorCountry("Please select country");
        return false;
      }
    }
  };

  const payNow = (e) => {
    e.preventDefault();

    if (!amount && !country) {
      setErrorAmount("Please enter amount");
      setErrorCountry("Please select country");
      return false;
    }
    if (!amount || amount === "" || amount === undefined) {
      setErrorAmount("Please enter amount");
      return false;
    }
    if (!country || country === "" || country === undefined) {
      setErrorCountry("Please select country");
      return false;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
    let data = {
      country: country,
      amount: amount,
    };
    axios.post(baseUrl + "/auth/priviligeCard", data, config).then((resp) => {
      var respp = resp.data;
      if (respp.success === true) {
        toast.dismiss();
        toast.success(respp.message);
        getPriviligeData();
        return false;
      } else {
        toast.dismiss();
        toast.error(respp.message);
      }
    });
  };

  const claim = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Navbar1 />
      <Sidebar1 />
      <main>
        <div className="dashbord_in">
          <div className="container custome-container">
            <h2 className="d_haddign">Privilege Card</h2>
            {!privilegeStatus ||
            privilegeStatus === 0 ||
            privilegeStatus === "0" ? (
              <div className="white_box">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <img
                        src="/assets/img/card.jpg"
                        alt="pay"
                        className="w100 play_pay_img"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <label>Country</label>
                    {paymentMethodDropdown()}
                    <div style={{ color: "red" }}>{errorCountry}</div>
                    <label>Select Sponsorship</label>
                    <input
                      value={amount}
                      className="form-control"
                      name="amount"
                      disabled={true}
                    />
                  </div>
                  <div style={{ color: "red" }}>{errorAmount}</div>
                </div>
                <div className="form-group  text-right">
                  <button className="btn btn_man" onClick={payNow}>
                    Pay Now
                  </button>
                </div>
              </div>
            ) : (
              <div className="white_box ">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <label className="h4">Card Details</label>
                    <div className="card_bg">
                      <div className="row">
                        <div className="card_value">
                          {status === true &&
                            `Name: ${firstName === null ? "" : firstName} ${
                              lastName === null ? "" : lastName
                            }`}
                          <br />
                          {status === true ? `Country: ${getCountry}` : ""}
                          <br />
                          {status === true ? `Amount: ${getAmount}` : ""}
                        </div>
                      </div>
                      <div className="row align-items-center mt-4">
                        <div className="form-group col-md-8 mb-md-0">
                          <span> *Claim 500 WKL Token </span>
                        </div>
                        <div className="col-md-4">
                          <div className="text-right">
                            <button className="btn btn_man" onClick={claim}>
                              Claim
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </main>

      <Footer1 />
    </>
  );
};

export default PriviligeCard;
